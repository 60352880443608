/******* STOP BOUNCE ON MAC CHROME ******/
/*
html {
     overflow: hidden;
     height: 100%;
 }

body {
    height: 100%;
    overflow: auto;
}
*/

.gg-cell-blink {
    -moz-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;
    transition: all .4s ease-in;
    background: #e17287 !important;
}


.gg-drop-down-on{
    background-color: #f5857c !important;

}

.gg-export-to-excel{
    background-color: #f5857c !important;
    /* //animation: blinker 1s linear infinite; */
}

/*@keyframes blinker {
  50% {
    opacity: 0;
  }
}*/


.gt-grid-action{
    margin:3px 0 0 0;
    padding:0;
    border:none;
    text-align:center ;
    -webkit-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    cursor:pointer;
}

.gg-new-row {
    background-color: rgba(28, 165, 207, .5) !important;
}


.gg-edit-row{
    background-color: rgba(51, 204, 51, .5) !important;
}

.gg-delete-row{
    background-color: rgba(245, 133, 124, .7) !important;
}


.gg-error-row{
   /*  background-color: rgba(245, 133, 124, 1) !important;
    background-color :rgba(245, 133, 124, 0.35) !important */
    background: #ffb6c147 !important;
}
.gg-error-col{
    color: #FFF !important;
    background-color: rgba(245, 133, 124, 1) !important;
}



.gg-lg-text-cnt{
    background-color:#ffffff;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border:1px solid #ccc;
    position:absolute;
    left:150px;
    top:100px;
    z-index:930;
    -webkit-box-shadow: 0 1px 0 1px #A6A6A6;
    box-shadow: 0 1px 0 1px #A6A6A6;
    display:none;
}

.gg-lg-text{
    margin:7px;
    height:100px;
    width:300px;
    font-size:11px;
    overflow-y: auto;

}

.gg-lg-text textarea{
    resize: none;

}



.gg-lg-text-cnt .btn{
    margin:5px 10px;

}

/*////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// hiddenTextAreaTemplate ////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////*/


.gg-total-text{
    font-size:11px;
    font-weight:bold;
}

.gg-green{
    background-color:#33cc33 !important;
}

.gg-red{
    background-color:#ee3324 !important;
}


.gg-col-order-overlay{
    z-index:253;
    background: rgba(0, 0, 0, .5);
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    display:none;
}


.gg-target-element{
    background-color: #0d7ca3 !important;
}

.gg-col-header-cont{
    padding: 10px;
    color: #fff;
    overflow: hidden;
    background-color: #cd5763;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.gg-col-header-cont h3{
    margin:0px;
    font-size:17px;
    font-weight:bold;
}


.gg-col-header-cont p{
    margin:3px 3px 3px 0px;
    font-size:12px;
}

.gg-col-header-cont i:hover {
    transform: scale(1.4);
}

.gg-col-order-cont{
    background-color: #fff;
    z-index:255;
    position:absolute;
    min-width:400px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    padding: 0;
    display:none;
    right: 17px;
    margin-right:20px;
    margin-right: auto;
    transition: left .5s ease;
}

.scrol-wrapper {
   /*  height: calc(100vh - 320px); */
   height: calc(100vh - 375px); /*after the cookie footer*/
    overflow: auto;
}

.adjust-modal, .adjust-modal-full-screen {
    left: 80px !important;
}

.gg-col-order-drag-cont{
    z-index:255;
    position:absolute;
    top:0px;
    left:0px;
    padding:3px;
    border:1px dashed #4c4f4f;
    background: rgba(0, 0, 0, .4);

}

.gg-col-order-drag-cont .gg-col-tools-header:hover{

}

.gg-col-tools-btns{
    margin: 0;
    padding: 10px;
}

.gg-col-tools-btns label{
    font-size:12px;
}


.gg-col-tools-btns .small-text{
    font-size:11px;
}



.gg-col-tools-btns .btn{
    float:right;
    margin-right:5px;
}


.gg-col-order-drag-cont .gg-col-tools-header{
    background-color:#1b841b;
}

.gg-col-headers{
    display: block;
    min-height:50px;
    max-height:400px;
}

.gg-col-tools-header:hover{

}


.gg-col-tools-header{
    float: left;
    height: 28px;
    margin-top: 5px;
    color: #ffffff;
    max-width: 250px;
    overflow: visible;
    line-height: 30px;
    margin-right: 5px;
    font-size: 11.5px;
    text-align: center;
    position: relative;
    border-radius: 15px;
    cursor: -webkit-grabbing;
    padding: 0px 8px 0px 0px;
    background-color: #5c5b5b;
    box-shadow: 1px 1px 2px #232122;
}

.gg-col-btn-sm{
    background-color: #616969;
}

.gg-col-tools-header:hover{

}

.gg-col-header-alpha{
    opacity: 0.2;
}

.gg-col-header-fade{
    background-color:#8d9797;
}


.gg-col-tools-header i{
    font-size:14px;
    float:left;
    margin:7px 5px 0px 8px;
    color:#33cc33;
    cursor: pointer;
}


.gg-col-temp-cont {
    height:auto;
    overflow: hidden;
}

.gg-col-create-cont , .gg-col-update-cont {
    height:auto;
    overflow: hidden;
    display:none;
}

.gg-col-temp-cont select, .gg-col-temp-cont input[type="text"],
.gg-col-create-cont select, .gg-col-create-cont input[type="text"],
.gg-col-update-cont select, .gg-col-update-cont input[type="text"]
{
    float:left;
    width:220px;
    margin:0 5px 0 0;
}


.gg-col-default-temp{
    float:left;
    margin-right:5px;
    font-size:11px;
}


.gg-col-temp-cont .btn, .gg-col-create-cont .btn , .gg-col-update-cont .btn{
    float:left;
    margin:0 5px 0 0;

}


.div-col-data {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently                              not supported by any browser */
}



.gg-selection{
    border:2px solid #337ab7;
    background: rgba(54, 54, 54, .3);
    z-index:5;
    position:absolute;
    top:0px;
    left:0px;
    height:200px;
    width:200px;
    pointer-events:none;
}



.gg-cell-selection{
    background: rgba(255, 255, 255, 0);
    z-index:16;
}



.gg-refresh-icon{
    position:absolute;
    background-color: rgba(54, 55, 56, 0.7);
    padding:15px;
    z-index:650 ;
    -webkit-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    color:#ffffff;
    display:none;

}



.gg-header .headerCol.batchSelect i.fa-check{
    color:#39da39;
    font-size:14px;
}


.batch-actions{
    background-color:#c2f0c2 ;
}

.batch-actions a:hover{
    background-color:#aceeac !important;
}


.div-col.batchSelect{
    text-align:center ;
}

.div-col.batchSelect i{
    color:green;
    font-size:14px ;
}


.gg-paste-btn{
    float:left;
    color:#1f1f1f;
    margin:2px 0px 0px 10px;
}

.gg-paste-save-btn{

}

.gg-hidden-ta-cnt{
    width:100px;
    height:20px;
    overflow:hidden;
    position: absolute;
    right:80px;
    top:-40px;
    overflow: hidden;
    z-index:200;
}

.gg-hidden-ta-cnt textarea{

}



.gizmo-grid{
    height: 100%;
    overflow: hidden;
}


*:focus {
    outline: none;
}

.gg-clearfix {
    clear: both;
}



.gg-full-screen{
    position:absolute;
    top:0;
    left:0;
    z-index:930;
    height:100%;
}

.gg-full-screen  [action="full-screen"] i{
    color:darkgray !important;
}


.gg-loader{
    width:900px;
    text-align:center;
    /* background: #000000 url("../../assets/img/gridLoaderBgnew.jpg"); */
    background: #f2f2f2;
    position:absolute;
    z-index: 640;
    opacity: 1;
    /* margin-top: 2px; */
    left: 94px;
    overflow: hidden;
}

/* using this for client-landing-page loader */
.client-page .gg-loader {
    width:100% !important;
    background: transparent;
}

.gg-loader .hide {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
}

.gg-loader-text{
    text-align:center;
    margin-left:auto;
    margin-right:auto;
    position: relative;
    top: 48%;
    transform: translateY(-48%);
}


.gt-loader-logo{
    position:relative;
    top: 50%;
    left: 50%;
    margin-left:-85px;
    margin-top:-85px;
    -webkit-box-shadow: 1px 1px 0px 0px #333333;
    box-shadow: 1px 1px 0px 0px #333333;
    color:#FFFFFF;

}

.gg-loader .logo{
    position:absolute;
    top: 38px;
    left: 38px;
    right: 0;
    bottom: 0;
}

.gg-loader .loader{
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.gg-loader .text{
    position:absolute;
    top: 140px;
    left: -10px;
    right: 0;
    bottom: 0;
    width:170px;
    font-size:12px;
}



.gg-loader i{
    color:#1ca5cf;
    font-size:50px;
}


.gg-loader p{
    margin-top:10px;
    color:#1ca5cf;
}
/*////////////////////// TAGS  //////////////////////////////*/

.gg-check-box-list{
    overflow-y: auto;
    height:300px ;
}


.gg-check-box-list li{
    margin:1px 5px 1px 5px;
}

.gg-check-box-list li:hover{
    background-color:#dddada ;
}




/*////////////////////// TAGS  //////////////////////////////*/

.gg-action-list{
    overflow-y: auto;
    max-height:300px ;
}


.gg-action-list li{
    margin:0px 0px 0px 0px;
    padding:4px 4px 4px 4px;
    font-size:13px;
}


.gg-action-list li i{
    margin-right:4px;

}



.gg-action-list li:hover{
    background-color:#dddada ;
}




/*////////////////////////////////////////////////////////  START MESSAGES   /////////////////////////////////////////////////////////////*/

.gg-drop-down{
    position:relative;
}

.gg-drop-down-menu{
    display:none;
    position:absolute;
    left:1px;
    top:1px;
}

.gg-drop-down-menu{
    margin:0;
    padding:0;
    text-align: left;
    font-size: 14px;
    -webkit-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    border:1px solid #d0cbcb;
    background:#ffffff;
}

.gg-drop-down .open{
    display:block;
}

.gg-drop-down-menu li{
    padding:3px 10px 0px 10px;
    margin:0;
    list-style: none;
    cursor: pointer;
}

.gg-drop-down-menu li:hover{
    background:#e9e6e6;
}

/* ERROR Div Styles */

.gg-error-container{
    position:absolute;
    z-index:1001;
    top:10px;
    left:20px;
    min-height:25px;
    background: #333;
    overflow-y: hidden;
    overflow-x: hidden;
    display:none;
    width:150px;
    height: auto !important;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    -webkit-box-shadow: 1px 1px 2px 2px #A6A6A6;
    box-shadow: 1px 1px 2px 2px #A6A6A6;
}
.gg-error-container .gg-list-container{
    color: #fff;
    font-size: 10px;
    padding: 3px;
    margin-top: 2px;
    text-align: center;
}

/*////////////////////////////////////////////////////////  START MESSAGES   /////////////////////////////////////////////////////////////*/

.gg-message-container{
    position:absolute;
    z-index:1001;
    top:10px;
    left:50px;
    min-height:50px;
    background: #ffffff;
    overflow-y: hidden;
    overflow-x: hidden;
    display:none;
    width:350px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    -webkit-box-shadow: 1px 1px 2px 2px #A6A6A6;
    box-shadow: 1px 1px 2px 2px #A6A6A6;
}


.gg-message-container .gg-message-relative .gg-list-container  {
    position:absolute;
    top:0px;
    left:0px;
    width:inherit;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all  1s ease-in-out;
    -o-transition: all  1s ease-in-out;
    transition: all  1s ease-in-out;
    z-index:1;
}

.gg-message-container .gg-detail-container{
    -webkit-transition: all  0.5s ease-in-out;
    -moz-transition: all  0.5s ease-in-out;
    -o-transition: all  0.5s ease-in-out;
    transition: all  0.5s ease-in-out;
    width:245px;
    position:absolute;
    top:0px;
    width:inherit;
    background: #ffffff;
    z-index:2;

}



.gg-message-container .gg-message{
    cursor: pointer;
    border-bottom: 1px solid #a6a6a6;
}


.gg-message-container .gg-message:hover{
    background:#e9e9e9 ;

}
.gg-message-container .gg-message  .profile-img{
    width:52px;
    margin:8px 0 0 5px;
    float:left;
}

.gg-message-container .gg-message  .profile-img img{
    width:52px;

}



.circular--square {
    border-radius: 50%;

}

.circular--square {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;

}




.gg-message-container .gg-message .gg-message-status{
    width: 7px;
    height: 65px;
    float: left;
}


.gg-message-container .gg-message .gg-message-status.follow_up{
    background:#ee3324;
}

.gg-message-container .gg-message .gg-message-status.note{
    background:#1ca5cf;
}



.gg-message-container .gg-message .gg-message-details{
    font-size: 12px;
    float: left;
    width: 245px;
    overflow: hidden;
    padding-right: 10px;
    color: #343737;
}


.gg-message-container .gg-message .gg-message-details .name{
    /* font-size:13px;
    font-weight:bold;
    margin:5px 0 0 0 ;
    float:left; */
    font-size: 13px;
    font-weight: bold;
    margin: 5px 0 0 0;
    padding-left: 10px;
    float: left;
    border-radius: 15px;
    background: #FFF0F0;
    width: 100%
}

.gg-message-container .gg-message .gg-message-details .name .date {
    font-size: 12px;
    margin: 3px 9px 0 0;
    position: relative;
    float: right;
    bottom: 3px;
    font-style: italic;
}


.gg-message-container .gg-message .gg-message-details .subject span{
    font-size: 12px;
    margin: 10px 0 0 0;
    float: left;
    width: 100%;
    text-overflow: ellipsis;
    padding-left: 10px;
    overflow: hidden;
    height: 20px;
}


.gg-message-container .gg-message  .gg-message-arrow{
    /* width:15px;
    margin:0 0px 0 0 ;
    float:left;
    line-height:64px;
    font-size: 16px;
    text-align: center; */
    float: left;
    font-size: 16px;
    text-align: center;
    background: #1ca5cf;
    padding: 2px 8px;
    border-radius: 35px;
    width: 25px;
    color: #ffffff;
    margin-top: 20px;
    transition: all .3s ease;
    height: 25px;
}

.gg-message-container .gg-message .gg-message-arrow:hover {
    transform: scale(1.1);
}

.gg-message-container .gg-message  .gg-message-arrow .fa-pulse{
    right: 3px;
    position: relative;
}

.gg-attachment-ellipse{
    width:15px;
    margin:0 0px 0 0 ;
    float:left;
    /* line-height:64px; */
    font-size: 16px;
    text-align: center;
}


/*//////////////////////////////////////////////// START DETAIL MESSAGE ////////////////////////////////*/


.gg-message-detail{
    background-color: #ffffff;
    /* border-bottom:1px solid #8f8686; */

}

.gg-message-scroll{
    overflow-y:auto;
}

.gg-messages-scroll{
    overflow-y:auto;
}


.gg-messages-scroll::-webkit-scrollbar-track, .gg-message-scroll::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 6px;
    background-color: #F5F5F5;
}

.gg-messages-scroll::-webkit-scrollbar, .gg-message-scroll::-webkit-scrollbar-track
{
    width: 8px;
    background-color: #F5F5F5;
}

.gg-messages-scroll::-webkit-scrollbar-thumb, .gg-message-scroll::-webkit-scrollbar-track
{
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

/*
 *  STYLE 2
 */




.gg-message-header{
    height:auto;

}
.gg-message-detail-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: none;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);

}

.gg-message-detail.follow_up{
    border-top: solid 6px #ff0000;
}

.gg-message-detail.note{
    border-top: solid 6px #1ca5cf;
}


.gg-message-detail .profile-img{
    width:50px;
    float:left;
}

.gg-message-detail .profile-img img{
    width:40px;
    margin:5px;
}


.gg-message-detail .details-col{
    float: left;
    margin: 5px 0 0 0;
    width: 240px;
    padding-left: 5px;
}

.gg-message-detail .details-col h2{
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    margin: 5px 0px 3px 0px;
}

.gg-message-detail .details-col h3{
    font-size:12px;
    font-weight:bold;
    margin:3px 0 0 0;
}

.gg-message-detail .details-col p{
    font-size:13px;
    margin:2px 0;
}

.gg-message-detail .edit-col{
    width:18px;
    float:left;
    text-align:center;
    cursor:pointer ;
    margin-left: 10px;
}

.gg-message-detail .gg-resolve-btn{
    font-size:14px;
    margin-top:5px;
}



.gg-message-detail .edit-btn:hover{
    background: #a8a8a8;
}



.gg-message-detail  .gg-col-data{
    padding:5px;
    background:#eebdbd;
}


.gg-message-tpl-top {
    height: 38px;
    font-size: 15px;
    list-style: none;
    color: #ffffff;
    overflow: hidden;
    font-weight: bold;
    padding-left: 5px;
    background: #cd5763;
}

.gg-message-top{
    height: 30px;
    padding: 5px;
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
    background: #cd5763;
}

.gg-message-top li.flex-item1{
    width:25px;
}

.gg-message-top li.flex-item1 i{
    margin-left:5px
}


.gg-message-top li.flex-item2{
    width:140px;
    border-bottom:1px solid #6d6f6f;
}

.gg-message-top li.flex-item3{
    width:30px;
    border-bottom:1px solid #6d6f6f;
}

.gg-message-top li.flex-item3 img{
    width:30px;
}


.gg-message-top li.flex-item4{
    width:140px;
    text-align: right;
    border-bottom:1px solid #6d6f6f;
}


.gg-message-header{
    height:auto;
    /* margin-left:25px; */
    font-weight:bold;
    font-size:14px;
}

.gg-message-header .top {
    height: 25px;
    margin: 5px 10px;
    padding: 2px 10px;
    border-radius: 3px;
    background: #ececec;
    border: 1px solid #ddd;
}

.gg-message-header .top ul {
    list-style-type: none;
    padding: 0;
}

.gg-message-header .top ul li {
    display: inline;
}

.gg-message-header .tool-bar ul {
    margin: 0;
    float: right;
    padding: 0;
    border-radius: 15px;
    border: 1px solid #ddd;
    background-color: #ececec;
}

.gg-message-header .tool-bar .icon {
    padding: 5px;
    font-size: 20px;
    display: inline-block;
    border-radius: 30px;
}
.gg-message-header .tool-bar .icon.active {
    color: #fff;
    padding: 5px;
    background-color: #333;
}
.gg-message-header .flex-wrapper {
    height: auto;
    display: flex;
    margin-top: 10px;
}

.gg-message-header .flex-wrapper .flex-1 {
    flex: 2;
    padding-left: 5px;
    text-align: center;
}

.gg-message-header .flex-wrapper .flex-1 span {
    padding-left: 5px;
    vertical-align: text-top;
}

.gg-message-header .flex-wrapper .flex-2 {
    flex: 5;
    font-size: 12px;
    box-shadow: none;
    background: transparent;
}

.gg-message-header .flex-wrapper .flex-2 ul {
    list-style: none;
    padding-left: 0px;
}

.gg-message-header .flex-wrapper .flex-2 ul li{
    display: flex;
}

.gg-message-header .flex-wrapper .flex-2 ul li .label {
    flex: 2;
    color: #333;
    font-size: 12px;
    text-align: left;
    overflow: hidden;
    padding-left: 3px;
    margin-bottom: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top-left-radius: 3px;
    border-top: 1px solid #ccc;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.gg-message-header .flex-wrapper .flex-2 ul li .value {
    flex: 2;
    font-size: 12px;
    overflow: hidden;
    padding-left: 3px;
    margin-bottom: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.gg-message-header .flex-wrapper .flex-2 ul li .circle-tag {
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 5px;
}

.gg-message-header .flex-wrapper .flex-3 {
    flex: 1;
    text-align: center;
}

.gg-message-header .flex-wrapper .flex-3 .ul-list-style {
    list-style: none;
    padding: 0;
}

.gg-message-header .flex-wrapper .flex-3 .ul-list-style .li-list-style {
    display: inline-block;
}

.gg-message-header .left{
    width:224px;
    float:left;
}

.gg-message-header .right{
    width:85px;
    float:left;
}

.gg-message-detail .edit-btn{
    width: 13px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    border-radius: 3px;
    margin: 5px 0 0 3px;
    background: #d0cbcb;
    border: 1px solid #d3d3d3;
}

.gg-message-body{
    width:310px ;
    margin-left:25px;
    margin-top:15px;
}

.gg-message-detail .user-assigned, .gg-message-detail .user-notify {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #efefef;
}

.gg-message-detail .user-assigned span .user-icon, .gg-message-detail .user-notify li .user-icon {
    font-size: 20px;
    color: #cd5763;
    margin-right: 5px;
    vertical-align: sub;
}

.gg-message-detail .user-notify {
    margin-top: 10px;
}

.gg-message-detail .user-notify li {
    list-style: none;
}

.gg-message-detail .body{
    margin: 5px;
    height: 50px;
    padding: 5px;
    display: none;
    font-size: 14px;
    overflow: hidden;
    border-radius: 3px;
    word-break: break-word;
    border: 1px solid #c7c7c7;
    background-color: #f9f9f9;
}


.gg-message-detail .body ul {
    margin:5px 5px 15px 5px;
    padding:0;
}

.gg-message-detail .body li{
    padding:0;
    font-size:12px;
    list-style:none;
    cursor: pointer;
    margin:5px 3px 0px 3px;
}

.gg-message-detail .tab-bar {
    color: #fff;
    font-size: 15px;
    background: #333;
}

.gg-message-detail .tab-bar ul {
    padding: 0;
    list-style: none;
}

.gg-message-detail .tab-bar ul li {
    cursor: pointer;
    padding: 5px 0px;
    text-align: center;
    display: inline-block;
}

.gg-message-detail .tab-bar ul li.active{
    background-color: #cd5763;
}
.gg-message-detail  .gg-attachment{
    height: 250px;
    margin: 5px;
    padding: 5px;
    font-size: 14px;
    /* overflow: h; */
    border-radius: 3px;
    word-break: break-word;
    border: 1px solid #c7c7c7;
    background-color: #f9f9f9;
}


.gg-message-detail  .gg-message-reply{
    height: 200px;
    border-radius: 3px;
    margin: 0px 5px 5px 5px;
}




.gg-message-detail  .gg-message-reply .gg-reply {
    width: 99%;
    margin-bottom: 3px;
    background-color: #f0f0f0;
    border: 1px solid #dcdcdc;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
}


.gg-message-detail  .gg-message-reply .gg-reply .gg-reply-comments{
    font-size: 12px;
    line-height: 14px;
}

.gg-message-detail  .gg-message-reply .gg-reply .gg-reply-comments span {
    width: 240px;
    padding-left: 5px;
    padding-bottom: 5px;
    display: inline-block;
    word-break: break-word;
}

.gg-reply .profile-img{
    width:50px;
    float:left;
    margin-left:5px;
    margin-right:15px;
}

.gg-reply .profile-img img{
    width:50px;
    border:1px solid #000000 ;
}

.gg-reply .details-col h3{
    font-size:11px;
    font-weight:bold;
    margin:5px 0 0 0;
}




.gg-reply-form {
    height: 40px;
    padding-right:30px;
    border: 1px solid #ccc;
    padding:5px 10px 10px 5px;
    background-color: #efefef;
}

.gg-reply-form .attach {
    cursor: pointer;
    font-size: 23px;
    position: absolute;
    margin-left: 10px;
}


.gg-message-reply .reply-form {
    padding:5px;
}


.reply-form-update{
    margin:5px 0px 0px 0px;
}


.reply-form-update  textarea,
.gg-message-reply .reply-form textarea {
    resize: none;
    width: 190px;
    height:60px;
    font-size:12px;
}

.gg-message-reply  .reply-form-update{
    display: none;
}


.gg-reply-text-area {
    height:30px;
    width: 265px;
    resize: none;
    border-radius: 5px;
    -moz-border-radius: 5px;
    padding:3px 10px 3px 10px;
    -webkit-border-radius: 5px;
}









/*////////////////////////////////////////////////////////  END  MESSAGES   /////////////////////////////////////////////////////////////*/



.gg-multi-select-container{
    color:#080808;
    background-color:#ffffff;
}

.gg-multi-select{
    background-color:#ffffff;
    height: 120px;
    min-height: 120px;
    width: 178px;
    min-width: 178px;
    overflow-y:auto;
    font-size:11px;
    border:1px solid #888888;
    margin:5px;
    padding:5px;
    white-space: nowrap;
}

.gg-multi-select div{
    height: 15px;
}

.gg-multi-select-btns{
    float:right;
    margin-right:5px;

}

.gg-multi-select-search{
    border:1px solid #888888;
    color: #080808;
    padding: 1px;
    overflow: hidden;
    margin:5px;

}

.gg-multi-select-search input{
    background:transparent;
    border:none;
    font-size:11px;
    color:#080808;
    height:20px;
    line-height: 18px;
    min-width:120px;
}


.gg-multi-select-search textarea{
    background:transparent;
    border:none;
    font-size:11px;
    float:left;
    color:#080808;
    height:20px;
    line-height: 20px;
    min-width:150px;
    resize: none;
}

.gg-multi-select-search i{
    color:#080808;
    font-size:11px;
    margin-top:3px;
    margin-left:3px;
    float:left;
}

.gg-modal-filter, .gg-modal-container {
    width:400px;
    min-height:90px;
    background-color:#ffffff;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    position:absolute;
    top:210px;
    /* left:100px; */
    z-index:999;
    border:none ;
    --webkit-box-shadow: 0 1px 2px 1px #858585;
    box-shadow: 0 1px 2px 1px #858585;
    display:none;
}


.gg-modal-container  .gg-modal-header,
.gg-modal-filter .gg-modal-header{
    height:25px;
    overflow:hidden;
    background-color:#4c4f4f;
    color:#ffffff ;
    cursor:move ;
}


.gg-modal-container .gg-modal-header .gg-title,
.gg-modal-filter .gg-modal-header .gg-title{
    float:left;
    margin:5px;
    font-size:12px;
    font-weight:bold;
}

.gg-modal-container .gg-modal-header  .gg-close{
    float:right;
    margin:2px 5px;
    cursor:pointer;
}


.gg-modal-container .gg-modal-ggBody {
    margin:10px;
}




.gg-modal-header .gg-title .gg-modal-header i,
.gg-modal-filter .gg-modal-header i{
    float:right;
    margin:5px;
    cursor:pointer;
}

.gg-modal-container  .gg-modal-ggBody,
.gg-modal-filter .gg-modal-ggBody,
.gg-modal-container .gg-modal-ggBody{
    margin:10px;
}


.gg-modal-container .gg-body {
    margin:10px;

}




.gg-modal-container .gg-modal-footer,
.gg-modal-filter .gg-modal-footer{
    margin:10px;
    text-align:right ;
}

.gg-divider{
    height:3px;
}

/*////////////////////// gg-context-menu //////////////////////////////*/


.gg-context-menu{
    position:absolute;
    z-index:2003;
    top:0px;
    left:0px;
    display:none;
    background-color: #ffffff;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    border:solid 1px #b4bcbc;
    -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);

}

.gg-context-menu ul{
    margin:4px 0px 4px 0px;
    padding:0;

}


.gg-context-menu li{
    margin:0;
    padding:0;
    list-style:none;
    position: relative;

}

.gg-context-menu li a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
    cursor:pointer;
    font-size:12px;
}

.gg-context-menu li a:hover{
    background-color:#f5f5f5;
}


/*////////////////////// Messages //////////////////////////////*/

.gg-tag-icon{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent #ff0000 transparent transparent;
    position:absolute;
    right:0px;
    top:0px;
}


.gg-edit-icon{
    width: 0;
    height: 0;
    border-style: solid;
    /* border-width: 0 10px 10px 0; */
    border-width: 10px 0px 0px 10px;
    border-color: transparent transparent transparent #8edaf2 ;
    position:absolute;
    left:0px;
    bottom:0px;
}

.gg-drilldown-icon{
    width: 0;
    height: 0;
    border-style: solid;
    /* border-width: 0 10px 10px 0; */
    border-width: 10px 0px 0px 10px;
    border-color: transparent transparent transparent #a7e0f2 ;
    position:absolute;
    left:0px;
    bottom:0px;
}

/* attachments */

.gg-tag-attachment{
    border-style: solid;
    border-width: 10px 10px 0  0 ;
    border-color:  #2bff00  transparent transparent transparent;
    position:absolute;
    top:0px;
}

.gg-tag-audit{
    border-style: solid;
    border-width: 10px 10px 0  0 ;
    border-color:  #FFB600  transparent transparent transparent;
    position:absolute;
    top:0px;
}


.gg-tag-error{
    border-style: solid;
    border-width: 10px 10px 0  0 ;
    border-color:  #ff0000  transparent transparent transparent;
    position:absolute;
    top:0px;
}



/*// autocomplete-suggestions///////////////////////////////////////////////////////*/


.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }
.autocomplete-suggestion{cursor:pointer; }
.autocomplete-suggestion:hover{background-color:#CCCCCC;}


/*///////////////////////////////////////////////////////////////*/


.gg-alert-container{
    width:100%;
    position:absolute;
    left:0px;
    top:0px;
    z-index:2000;

}


.gg-alert-container .gg-alert-close{

}

.gg-alert{

}



/******* STOP BOUNCE ON MAC CHROME ******/
.gizmo-grid .neg-num-color{
    /* color: #ea6358; */
    color: #c51b0d;
}

.gizmo-grid .right-align, .gizmo-grid .right-align > div{
    text-align:right;
}

.gizmo-grid .center-align, .gizmo-grid .center-align > div{
    text-align:center;
}


.gizmo-grid .success-col, .gizmo-grid .success-col > div{
    color:green;
}




.right-align{

}

.total-row{
    background-color:#0078a3 !important;
}

.ggTotalRow{
    color: #fff;
    background-color: #d9b840 !important;
}


.ggTotalRow.selected-prime-row div{
    color:#000000 !important;
}

.ggTotalSubRow{
    color: #090808;
    background-color: #5d595f85!important;
}

.ggTotalSubRow.selected-prime-row div{
    color:#000000 !important;
}

.ggCellData{
    font-weight: bold;
    color: #e17287;
    background-color: #b9bbbb
}


.grid-message-btn{
    width:10px;
    height:10px;
    position:absolute;
    right:5px;
    top:2px;
    font-size:14px;
    cursor:pointer;
    color:#1490b6 ;
}


.grid-link-btn, .grid-drilldown-btn{
    width:10px;
    height:10px;
    position:absolute;
    left:2px;
    top:2px;
    font-size:14px;
    cursor:pointer;
    color:#1490b6;
    margin-right:10px;
}

.drillDown .div-col-data, .link  .div-col-data {
    padding-left:15px;
}


.grid-form-btn{
    width:10px;
    height:10px;
    position:absolute;
    right:5px;
    top:2px;
    font-size:14px;
    cursor:pointer;
    color:#1490b6 ;
}

.right-align .grid-form-btn{
    left:5px;
    top:2px;
}






.selected-filter .fa{
    color:#000000 !important;
}


.gg-form-container{
    display:none;
    width:100px;
    height:200px;
    position:absolute;
    left:1px;
    top:1px;
    z-index:1234;
    padding:10px;

}


.gg-modal{
    width:100%;
    height:100%;
    background-color: #ffffff;
    border:1px solid #000000;
    overflow:auto ;
}


.gg-form-container .header{
    width:100%;
    height:40px;
    background-color:#1ca5cf;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}


.gg-form-container .header i{
    line-height:40px;
    color:#FFFFFF;
    margin:0 10px 0 10px;
    padding:0;
    font-size:18px;
}


.gg-form-container .header h1{
    line-height:40px;
    color:#FFFFFF;
    margin:0 0 0 10px;
    padding:0;
    font-size:18px;

}




.gizmo-grid .edit-grid-value{
    display:none;
    width:100px;
    height:50px;
    position: absolute;
    left: 0;
    top:0;
}

.gizmo-grid .selected_input{




}




.gizmo-grid{
    width:100%;
    background-color: #777777;
    overflow:hidden;
}

.gizmo-grid .pivot-grid-resize {
    width: calc(100% - 270px) !important;
}

.gizmo-grid .pivot-resize {
    width: 25px !important;
    border: none !important;
}

.gizmo-grid .pivot-resize-fullscreen {
    height: calc(100% - 62px) !important;
}

.gizmo-grid .isPivot-gridLayout {
    width: calc(100% - 25px) !important;
}

.gizmo-grid .isPivotExpand-gridLayout {
    width: calc(100% - 270px) !important;
}


.gizmo-grid .gg-tools{
    width:100%;
    height:30px;
    transition: width 0.2s ease;
    border-top:1px solid #5d6161;
}

.gizmo-grid .gg-dragger-bg {
    background-color: #4f494d;
    padding: 5px 10px;
    border-top: 1px solid #8e8e8e;
    border-bottom: 1px solid #464a4a;
    height: 40px;
}

.dndDragging {
    background-color: #363738;
    padding: 5px 15px;
    box-shadow: 1px 1px 2px #232122;
    border-radius: 15px;
    color: #ffffff;
    opacity: 1;
}

.dndDragging .drag-handler {
    display: none;
}

.dndDraggingSource {
    background-color: transparent;
    box-shadow: none;
    color: #333;
}

.gizmo-grid .gg-dragger-bg .dndPlaceholder {
    list-style: none;
}
.gizmo-grid .gg-dragger-bg .gg-col-pivot-header{
    float: left;
    color: #333;
    overflow: visible;
    margin-right: 7px;
    font-size: 12px;
    line-height: 0px;
    border-radius: 35px;
    box-shadow: 2px 3px 3px #292929;
    padding: 5px 10px;
    background-color: #cacaca;
    border: 1px solid #c5c5c5;
    max-width: 250px;
}

.gizmo-grid .gg-dragger-bg .gg-col-pivot-header i{
    margin-left:5px;
    cursor: pointer;
    color: #ee3324;
    font-size: 14px;
    transition: transform .3s ease;
}

.gizmo-grid .gg-dragger-bg .gg-col-pivot-header i:hover{
    transform: scale(1.4);
}

.gizmo-grid .pivot-right-panel {
    /* height: calc(100% - 182px); */
   height: calc(100% - 237px);
    width: 270px;
    background-color: #efefef;
    position: absolute;
    z-index: 639;
    right: 0px;
    border: 1px solid #ccc;
    display: flex;
    transition: width .2s ease;
}

.gizmo-grid .pivot-right-panel .pivot-left-box {
    width: calc(100% - 25px);
    display: inline-block;
    border-right: 1px solid #ccc;
    overflow-y: auto;
}

/* width */
.gizmo-grid .pivot-right-panel .pivot-left-box::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .gizmo-grid .pivot-right-panel .pivot-left-box::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .gizmo-grid .pivot-right-panel .pivot-left-box::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .gizmo-grid .pivot-right-panel .pivot-left-box::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .gizmo-grid .pivot-right-panel .pivot-left-box .select-sec {
    display: flex;
    padding: 0 !important;
    text-align: center;
    cursor: pointer;
  }

  .gizmo-grid .pivot-right-panel .pivot-left-box .select-sec div {
    flex: 1;
    padding: 7px 15px;
    border-right: 1px solid #ccc;
  }

  .gizmo-grid .pivot-right-panel .pivot-left-box .select-sec div:hover {
    background-color: #ddd;
  }

  .gizmo-grid .pivot-right-panel .pivot-left-box .select-sec .type-selected {
    background-color: #ddd;
    box-shadow: inset 0 0 5px #cacaca;
}

  .gizmo-grid .pivot-right-panel .pivot-left-box .select-sec div:first-child {
    border-right: 1px solid #ccc;
    }

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-sec,
.gizmo-grid .pivot-right-panel .pivot-left-box .select-sec,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list {
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list {
    border-bottom: none;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .dragr-plc-hldr {
    padding: 5px;
    font-style: italic;
    border: 1px solid #ddd;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-sec input {
    vertical-align: top;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .handle {
    display: inline-block;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .handle .drag-handler {
    margin-right: 5px;
    cursor: grabbing;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .pivot-values .item-label{
    margin-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 190px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #dfdfdf;
    padding: 0 5px;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .row-labels,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .val-labels,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .col-labels {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 3px;
    margin: 5px 0px;
    cursor: move;
    box-shadow: 0px 1px 3px 0px #ccc;
}
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .row-labels li,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .val-labels li,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .col-labels li {
    display: inline-block;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .row-labels li:first-child,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .col-labels li:first-child {
    width: 155px;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .row-labels li .row-label-dlt,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .val-labels li .val-label-dlt,
.gizmo-grid .pivot-right-panel .pivot-left-box .hdr-array-list .col-labels li .col-label-dlt {
    cursor: pointer;
    font-size: 18px;
    color: #848484;
}

.icn-disabled {
    opacity: 0.5;
    pointer-events: none;
}

/* .row-list-popover, .val-list-popover, .col-list-popover {
    top: 0px !important;
} */

.row-list-popover {
    top: 50px;
}

.row-list-popover .row-list-wrapper,
.val-list-popover .val-list-wrapper,
.col-list-popover .col-list-wrapper {
    padding: 5px 0px;
    max-height: 430px;
}

.row-list-popover .row-list-wrapper .row-search-wrapper,
.val-list-popover .val-list-wrapper .val-search-wrapper,
.col-list-popover .col-list-wrapper .col-search-wrapper {
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
}

.row-list-popover .row-list-wrapper .row-search-wrapper .srch,
.val-list-popover .val-list-wrapper .val-search-wrapper .srch,
.col-list-popover .col-list-wrapper .col-search-wrapper .srch {
    top: 12px;
    left: 14px;
    color: #333;
    position: absolute;
}

.row-list-popover .row-list-wrapper .row-bdy-wrapper,
.val-list-popover .val-list-wrapper .val-bdy-wrapper,
.col-list-popover .col-list-wrapper .col-bdy-wrapper {
    overflow-y: auto;
    max-height: 388px;
}

.row-list-popover .row-list-wrapper .row-search-wrapper .row-search,
.val-list-popover .val-list-wrapper .val-search-wrapper .val-search,
.col-list-popover .col-list-wrapper .col-search-wrapper .col-search {
    height: 25px;
    padding-left: 22px;
    margin-bottom: 5px;
    width: calc(100% - 15px);
}

.row-list-popover .row-list-wrapper li,
.val-list-popover .val-list-wrapper li,
.col-list-popover .col-list-wrapper li {
    list-style: none;
    padding: 0px 10px;
    cursor: pointer;
}

.row-list-popover .row-list-wrapper li:hover,
.val-list-popover .val-list-wrapper li:hover,
.col-list-popover .col-list-wrapper li:hover {
    background-color: #edebeb;
}

.gizmo-grid .pivot-right-panel .pivot-left-box .select-sec .sigma {
    transform: rotate(-90deg);
    font-weight: 700;
    font-size: 14px;
    display: inline-block;
    color: #777;
}

.row-list-popover .row-list-wrapper::-webkit-scrollbar,
.val-list-popover .val-list-wrapper::-webkit-scrollbar,
.col-list-popover .col-list-wrapper::-webkit-scrollbar {
    width: 5px;
}
 
.row-list-popover .row-list-wrapper::-webkit-scrollbar-track,
.val-list-popover .val-list-wrapper::-webkit-scrollbar-track,
.col-list-popover .col-list-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.row-list-popover .row-list-wrapper::-webkit-scrollbar-thumb,
.val-list-popover .val-list-wrapper::-webkit-scrollbar-thumb,
.col-list-popover .col-list-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.gizmo-grid .pivot-right-panel .pivot-right-box {
    display: flex;
    align-items: center;

}

.gizmo-grid .pivot-right-panel .pivot-right-box .btn-wrapper {
    padding: 0 3px;
    cursor: pointer;
}

.gizmo-grid .pivot-right-panel .pivot-right-box .btn-wrapper .lable-btn{
    writing-mode: vertical-rl;
    width: 17px;
}

.gizmo-grid .gg-tools ul{
    margin:0 0 0 10px;
    padding:0;
}

.gizmo-grid .gg-tools > .pull-left > ul > li{
    margin: 3px 0 0 0;
    list-style: none;
    display: inline-block;
    background: #838383;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #393939;
    position: relative;
    padding: 0px 3px 0px 3px;
    box-shadow: 0 3px 2px -2px #333;
}

.gizmo-grid .gg-tools > .pull-left > ul > li a{
    font-size:11px;
    color:#000;
}

.gt-col-temp-drop .dropdown-menu li.active a{
    color: #fff !important;
    background-color: #337ab7;
}

.gizmo-grid .gg-tools > .pull-left > ul > li a:hover {
    text-decoration: none;
}
.gizmo-grid .pivot-right-panel .pivot-left-box .search {
    padding: 7px 15px;
    border-bottom: 1px solid #ccc;

}
.gizmo-grid .pivot-right-panel .pivot-left-box .search  input{
    vertical-align: top;

}

.gizmo-grid .gg-tools > .pull-left > ul > li:hover{
    background-color:#b9b6b6;
    /*border:1px solid #414444;
     border-top: 1px solid #414444;
    border-right: 1px solid #414444;
    border-bottom: 1px solid #414444;
    border-left: 1px solid #414444;
    -moz-box-shadow: inset 0 1px 0 0 #6a7272;
    -webkit-box-shadow: inset 0 1px 0 0 #6a7272; */
}

.gizmo-grid .gg-tools li.hover-on{
    border:1px solid #414444;
    /* background-color:#636666; */
    border-top: 1px solid #414444 !important;
    border-right: 1px solid #414444 !important;
    border-bottom: 1px solid #414444 !important;
    border-left: 1px solid #414444 !important;
    /* -moz-box-shadow: inset 0 1px 0 0 #6a7272;
    -webkit-box-shadow: inset 0 1px 0 0 #6a7272; */
    color: #000000!important;
}

.gizmo-grid .gg-tools li.hover-on a{
    color: #000000!important;
    text-decoration: none;
    font-size:12px;
}



.gizmo-grid .gg-tools li .highlight-drop{
    position:absolute;
    left:0px;
    top:30px;
    width:300px;
    height:300px;
    background-color:#ffffff;
    z-index:1007;
}


.gizmo-grid .gg-tools li a i, .gizmo-grid .gg-tools li i {
    font-size:14px;
    color:#1f1f1f;
}


.gg-orange{
    color: #ccaa00;

}



.color-drop{
    margin-top:7px;
    margin-bottom:7px;
}

.color-row{
    clear:both;
    overflow:hidden;
    height:25px;
    margin:0px 10px 0px 10px;
}


.color-row .color-block{
    width:16px;
    height:16px;
    float: left;
    margin-left:2px !important;
    -webkit-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
}




.gg-menu-action .color-block{
    width:16px;
    height:16px;
    float: right;
    margin-left:2px !important;
    -webkit-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    margin-left:15px;
}



.gg-context-menu .divider {
    height: 1px;
    margin: 6px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}


.gg-menu-action .dark-red,
.color-row .dark-red {
    background-color:#a20100;
    border:1px solid #a20100;
}

.gg-menu-action .red,
.color-row .red {
    background-color:#c73f4c;
    border:1px solid #c73f4c;
}
.gg-menu-action .green,
.color-row .green {
    background-color:#3ad23a;
    border:1px solid #3ad23a;
}
.gg-menu-action .pink,
.color-row .pink {
    background-color:#f20b7f;
    border:1px solid #f20b7f;
}
.gg-menu-action .yellow,
.color-row .yellow {
    background-color:#ffff00;
    border:1px solid #ffff00;
}
.gg-menu-action .blue,
.color-row .blue {
    background-color:#1ca5cf;
    border:1px solid #1ca5cf;
}


.gg-menu-action .no-fill,
.color-row .no-fill {
    background-color:transparent;
    border:1px solid #000000;
}


/*////////////////////////   //////////////////////////////*/

.text-color-green {
    color: #33cc33 !important;
}

.text-color-yellow {
    color: #ffff00 !important;
}

.text-color-dark-red {
    color: #a20100 !important;
}

.text-color-red {
    color: #c73f4c !important;
}

.text-color-pink {
    color: #f20b7f !important;

}

.text-color-blue {
    color:#1ca5cf !important;
}

/*//////////////////////////// DIV ROW HIGHLIGHT  ///////////////////////////////////*/

.div-row .high-light-green,
.high-light-green .div-col{
    background-color:#3ad23a;
}

.div-row .high-light-green,
.high-light-green .div-col{
    background-color:#3ad23a;
}

.div-row .high-light-yellow,
.high-light-yellow .div-col{
    background-color:#ffff00;
}

.div-row .high-light-dark-red,
.high-light-dark-red .div-col{
    background-color:#a20100;
}

.div-row .high-light-red,
.high-light-red .div-col{
    background-color:#c73f4c;
}

.div-row .high-light-pink,
.high-light-pink .div-col{
    background-color:#f9c2de;
}

.div-row .high-light-blue,
.high-light-blue .div-col{
    background-color:#1ca5cf;
}


.high-light-green {
    background-color:#3ad23a;
}


.div-row .high-light-green {
    background-color:#3ad23a;
}

.div-row .high-light-yellow{
    background-color:#ffff00;
}

.div-row .high-light-dark-red{
    background-color:#a20100;
}

.div-row .high-light-red{
    background-color:#c73f4c;
}

.div-row .high-light-pink{
    background-color:#f9c2de !important;
}


.div-row .high-light-blue{
    background-color:#1ca5cf !important;
}


.gizmo-grid .gg-tools .dropdown-menu{

}


.gizmo-grid .gg-tools .dropdown-menu > li{
    margin:5px 0px;
}


.gizmo-grid .gg-tools .dropdown-menu > li{
    margin:5px 0px;
}

.gizmo-grid .gg-tools .dropdown-menu > li.dotted{
    border:1px dotted #4c4f4f;
    margin:3px;
    font-size: 11px;
    padding:4px;
    color:#4c4f4f ;
}




/*///////////////////////////////////////////////////////////////////////////////////*/
/*////////////////////////////////////// GIZMO HEADER ///////////////////////////////*/
/*///////////////////////////////////////////////////////////////////////////////////*/

.gizmo-grid .gg-header{
    width:100%;
    font-size:12px;
    position:relative;
    cursor:default;
    transition: width 0.2s ease;
}


.header-end-cap{
    width:14px;
    background-color:#616969;
    height:inherit;
    right:0;
    top:0;
    position:absolute;
    z-index:115;
    border-top:1px solid #232122;
    border-bottom:1px solid #232122;
    border-left:1px solid #232122;
}


.overflow{
    height:30px;

    display:block;
}

.gizmo-grid .gg-header-left-col{
    height:inherit;
    border-bottom:1px solid #232122;
    border-top:1px solid #232122;
    position:absolute;
    top:0px;
    left:0px;
}



.gizmo-grid .gg-header .gg-header-left-col .headerCol{
    height:28px;
    border-right:1px solid #232122;
    width:100px;
    float:left;
    overflow:visible;
    text-align:center;
    /*line-height:30px;*/
    padding-top:5px;
    word-break: break-word;
    position:relative;
    display:block;
}


.gizmo-grid .gg-header-right-col{
    height:inherit;
    border-bottom:1px solid #232122;
    border-top:1px solid #232122;
    position:absolute;
    top:0px;
    left:650px;
}


.gizmo-grid .gg-header .gg-header-right-col .headerCol{
    height:28px;
    border-right:1px solid #232122;
    width:100px;
    float:left;
    overflow:visible;
    text-align:center;
    /* line-height:30px;  */
    position:relative;
    padding-top: 5px;
    word-break: break-word;
}

/* .gizmo-grid .selected-col-header.edit-col-header{
    background-color: #c86f68 !important;
} */




.reorder-col-block{
    height:100%;
    width:100px;
    position:absolute;
    top:88px;
    left:230px;
    background-color:#000000;
    /* Netscape */
    -moz-opacity: 0.2;
    /* Safari 1.x */
    -khtml-opacity: 0.2;
    /* Good browsers */
    opacity: 0.2;
    display:none;
    z-index:100;
}







.sortHeader{
    float:left;
    cursor:pointer;
}

.sortHeader:hover{
    color:#ffffff;
}


.sortHeader .fa-sort-alpha-asc, .sortHeader .fa-sort-alpha-desc{
    color: #ffffff;
}




.col-resize-handle{
    width:4px;
    height:28px;
    cursor:w-resize;
    z-index:102;
    position:absolute;
    bottom:0;
    right:0;

}


.col-lil{
    width:10px;
    height:28px;
    cursor:w-resize;
    z-index:102;
    position:absolute;
    bottom:0;
    left:0;
    background-color: #080808;
}

.col-resize-handle:hover{
    background-color:#08a5e1;
}

.resize-col-line{
    width:4px;
    height:100%;
    display:none;
    z-index:102;
    right:0px;
    top:1px;
    position:absolute;
    border-right:1px solid #08a5e1;
    cursor:w-resize;
}


.resize-col-icon{
    width:10px;
    height:30px;
    background-color:#1f586a;
}


.col-line{
    width:2px;
    height:100%;
    background-color:#1ca5cf;
    position:absolute;
    top:1px;
    left:10px;
    display:none;
}


.fixed-col-handler{
    width:4px;
    height:100%;
    background-color:#000;
    position:absolute;
    top:0px;
    left:0px;
    z-index:100;
    /* Netscape */
    -moz-opacity: 0.4;
    /* Safari 1.x */
    -khtml-opacity: 0.4;
    /* Good browsers */
    opacity: 0.4;
    cursor: -webkit-grab;
}


.fixed-col-handler:hover{
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1;
    background-color:#000 ;
}

.hide-content {
    display: none;
}


/*///////////////////////////////////////////////////////////////////////////////////*/
/*////////////////////////////////////// GIZMO HEADER ///////////////////////////////*/
/*///////////////////////////////////////////////////////////////////////////////////*/


.div-row{
    height:30px;
    background-color:#ffffff;
    border-bottom:1px solid #cccccc;
    width:100%;
    font-size:11px;
}

/*.div-row:nth-child(even) {background: #f0eeee}
.div-row:nth-child(odd) {background: #ffffff}*/


.batch-row{
    background-color: rgba(51, 204, 51, .3) !important;
}


.odd-row{
    background-color: #f0eeee;
}


.selected-row{
    background-color:#d0e8f1;
}



.selected-row .div-col{
    border-bottom:1px solid #0d7ca3;
    border-top:1px solid #0d7ca3;
    -moz-box-shadow:   inset  0  4px 4px -4px #0d7ca3,
    inset  0 -4px 4px -4px #0d7ca3;
    -webkit-box-shadow: inset  0  4px 4px -4px #0d7ca3,
    inset  0 -4px 4px -4px #0d7ca3;
    box-shadow: inset  0  4px 4px -4px #0d7ca3,
    inset  0 -4px 4px -4px #0d7ca3;
}





.selected-row .div-col-prime, .selection-row  .div-col-prime{
    background-color: #616969;
    border-bottom:1px solid #337ab7;
    border-top: 1px solid #337ab7;
}


.div-row .selected-prime-row{
    border-bottom:1px solid #337ab7;
    border-top:1px solid #337ab7;
}


.div-row:nth-child(odd){
    background:#edebeb;
}


.div-col{
    height:inherit;
    border-right:1px solid #cccccc;
    width:100px;
    float:left;
    position:relative;
    overflow:hidden;
    border-bottom:1px solid #cccccc;
}

.div-col div[colobj="error_msg"]{
    background: red;
}


.selected-col{
    border-left:1px solid #0d7ca3;
    border-right:1px solid #0d7ca3;
    -webkit-box-shadow:inset 0 -4px 4px 4px #0d7ca3, inset  4px 0 -4px -4px #696868;
    box-shadow:inset 0 -4px 4px 4px #0d7ca3, inset  4px 0 -4px -4px #696868;
    background-color: #d0e8f1;




}


.div-col-prime{
    height:inherit;
    border-right:1px solid #000000;
    border-bottom:1px solid #000000;
    text-align:center;
    font-weight:bold;
    float:left;
    position:relative;
    overflow:hidden;
    line-height:30px;
    cursor:default;
}



.div-col > .div-col-data{
    margin:5px;
}



.resize-row-icon{
    width:40px;
    height:10px;
    background-color:#08a5e1;
}

.resize-row-line{
    width:100%;
    height:10px;
    display:none;
    z-index:1002;
    left:0;
    top:0;
    position:absolute;
    border-bottom:1px solid #08a5e1;
    cursor:n-resize;
}



.row-resize-handle{
    width:40px;
    height:4px;
    position:absolute;
    bottom:0;
    left:0;
    cursor:n-resize;
    z-index:1002;
}

.row-resize-handle:hover{
    background-color:#08a5e1;
}







/*---------------------------------------------- DROP MENU ---------------------------------------------------*/


.btn-super-sm, .btn-super-sm:active{
    padding: 0px 2px !important;
    font-size: 10px !important;
    line-height: 1.5 !important;
    border-radius: 2px !important;
    background-color:#4c4f4f !important;
    border-color:#4c4f4f;
    color:#adaaaa !important;
}

.btn-super-sm:hover {
    background-color:#646666 !important;
    border: 1px solid #646666 !important;
    color:#ffffff !important;
}


.open .btn-super-sm {
    background-color:#646666 !important;
    border: 1px solid #646666 !important;
    color:#ffffff !important;
}




.gg-dropMenu .btn-default.active,
.gg-dropMenu .btn-default.focus,
.gg-dropMenu .btn-default:active,
.gg-dropMenu .btn-default:focus,
.gg-dropMenu .btn-default:hover,
.gg-dropMenu .open>.dropdown-toggle.btn-default{
    padding: 0px 2px !important;
    font-size: 10px !important;
    line-height: 1.5 !important;
    border-radius: 2px !important;
    background-color:#4c4f4f;
    border-color:#4c4f4f;
    color:#adaaaa;

}

.dropdown-menu>li>a{
    padding:1px 20px;
}



.btn-filterOn, .btn-filterOn:hover{
    background-color:#21afdb !important;

}


.dropdown-menu .divider{
    background-color:#95a4a4;
    margin:2px 0px;
}


.gg-dropMenu{
    float:right;
    cursor:pointer;
    margin-right:5px;
    margin-left: 5px;
    margin-top:-2px;


}


.gg-dropMenu .open{
    padding: 1px 4px !important;
    font-size: 10px !important;
    line-height: 1.5 !important;
    border-radius: 2px !important;
    background-color:#1490b6;
    border:1px solid #95a4a4;
    color:#adaaaa;
}


.gg-menu-container{
    -webkit-box-shadow: 0 1px 4px 2px #8A8A8A;
    box-shadow: 0 1px 4px 2px #8A8A8A;
    border:1px solid #95a4a4;

}


.dropdown-menu > li{
    position:relative;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
    cursor:pointer;
    font-size:12px;

}
.dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    display:none;
    margin-top: -1px;
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    border-left-color:#fff;
    box-shadow:none;
}

.right-caret:after{
    content:"";
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 4px solid orange;
    display: inline-block;
    height: 0;
    opacity: 0.8;
    vertical-align: middle;
    width: 0;
    margin-left:5px;
}
.left-caret:after{
    content:"";
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-right: 4px solid orange;
    display: inline-block;
    height: 0;

    opacity: 0.8;
    vertical-align: middle;
    width: 0;
    margin-left:5px;
}



.gg-input-text{
    font-size:12px;

}

.gg-input-check{
    float:right;
}



.gg-drag-fill{
    width:2px;
    height:2px;
    background-color:#337ab7;
}





.gg-menu-action .drop-search{
    background-color:#ffffff;
    padding:0px 0px;
    -webkit-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    margin:0 10px 0px 10px !important;
    border:1px solid #616969;
}

.gg-menu-action .drop-search i{
    color:#222222;
}


.gg-menu-action .drop-search .gg-dropMenu-input {
    background-color:#ffffff;
    border:none;
    width:115px;
    height:20px;
    line-height:20px;

}




.gg-dropMenu-input{
    color:#080808;
    padding:4px;
    height:25px;
    line-height:25px;
}





/*///////////////////////////////////////////////////////////////////////////////////*/
/*////////////////////////////////////// GIZMO FOOTER ///////////////////////////////*/
/*///////////////////////////////////////////////////////////////////////////////////*/



/*///////////////////////////////////////////////////////////////////////////////////*/
/*////////////////////////////////////// GIZMO FOOTER ///////////////////////////////*/
/*///////////////////////////////////////////////////////////////////////////////////*/

.gg-search-holder{
    width:170px;
    height: 23px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    margin-top:2px;
    margin-right:15px;
    -webkit-transition: background-color 700ms linear;
    -moz-transition: background-color 700ms linear;
    -o-transition: background-color 700ms linear;
    -ms-transition: background-color 700ms linear;
    transition: background-color 700ms linear;
}

.gg-search-holder:hover{
    -webkit-transition: background-color 700ms linear;
    -moz-transition: background-color 700ms linear;
    -o-transition: background-color 700ms linear;
    -ms-transition: background-color 700ms linear;
    transition: background-color 700ms linear;
}



.gg-search-holder:hover .gg-global-search, .gg-search-holder .gg-global-search:focus{
    /* background-color:#FFFFFF; */
    -webkit-transition: background-color 700ms linear;
    -moz-transition: background-color 700ms linear;
    -o-transition: background-color 700ms linear;
    -ms-transition: background-color 700ms linear;
    transition: background-color 700ms linear;
}






.gg-global-search{
    height: 20px;
    border:none;
    width:126px;
    margin-left:2px;
    outline:none;
    font-size:12px;
    float:left;
    margin-top:1px;
    -webkit-transition: background-color 700ms linear;
    -moz-transition: background-color 700ms linear;
    -o-transition: background-color 700ms linear;
    -ms-transition: background-color 700ms linear;
    transition: background-color 700ms linear;

}


.gg-search-holder  .fa-search{
    margin-left:5px;
    margin-right:2px;
    margin-top:4px;
    color:#3c3c3c;
    float:left;
}


.gg-search-holder  .fa-times{
    margin-left:5px;
    margin-right:2px;
    margin-top:4px;
    color:#3c3c3c;
    float:left;
    cursor:pointer;
}


.gg-search-holder  .fa-times:hover{
    color:#000000;
}


.gizmo-grid .gg-footer{
    width:100%;
    background-color:#cccccc;
    height:60px;
    position:relative;

}

.gizmo-grid .gg-footer .gg-footer-filters{
    width:100%;
    height:32px;
}

.gizmo-grid .gg-footer .gg-footer-filters .filter-icon{
    height:100%;
    width:42px;
    float:left;
    text-align:center;
    cursor:pointer;

}

.gizmo-grid .gg-footer .gg-footer-filters .filter-icon i {
    color: #000000;
    font-size: 20px;
    margin-top: 6px;
}



.gizmo-grid .gg-footer .gg-footer-filters .gg-clear-all {
    color:#000000;
    background-color:#333033;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    float:left;
    height:26px;
    line-height:26px;
    width: 30px;
    margin:3px 0px 0px 3px;
    text-align: center;
    -webkit-transition: width 4s; /* Safari */
    transition: width 4s;
    cursor: pointer;

}


.gizmo-grid .gg-footer .gg-footer-filters .gg-clear-all i{
    color:#c73f4c !important;
    font-size:14px;
    transition: font 0.3s ease;


}


.gizmo-grid .gg-footer .gg-footer-filters .gg-clear-all:hover i {
    color: #c73f4c !important;
    font-size: 20px;


}



.gizmo-grid .gg-footer .gg-footer-filters .filter-item{
    color:#b8b7b7;
    background-color:#333033;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    float:left;
    height:26px;
    margin:3px 0px 0px 3px;
    text-align: center;
    padding-left:10px;
    padding-right:10px;
    font-size:11px;
    line-height:26px;


}


.gizmo-grid .gg-footer .gg-clear-item{
    float:right;
    margin-left: 5px;
    color:#c73f4c !important;
    font-size:14px;
    transition: font 0.3s ease;
    cursor: pointer;
}

.gizmo-grid .gg-footer .gg-clear-item:hover{
    font-size:18px;
}



.gizmo-grid .gg-footer .gg-footer-details{
    height:28px;
    line-height:28px;
    font-size:12px;
    padding-left: 10px;
}

.gg-footer .footer-details{
    float:left;

}

.gg-footer .gg-footer-details .footer-go-to{
    float:left;
    margin-left: 15px;

}

.deleteCountContainer{
    background: hsl(320deg 4% 30%) !important;
    border: hsl(36deg 100% 84%) !important;
    box-shadow: none !important;
}

.gg-footer .footer-total{
    float:right;
    margin-right:10px;
}

.gg-delete-count{
    color: hsl(0deg 0% 100%);
    background: hsl(354deg 54% 57%);
    padding: 3px 10px;
    border-radius: 4px;
    font-size: 11px;
    box-shadow: 0 3px 2px -2px #333;
}

.gg-total-input{
    height:20px;
    border:none;
    min-width:100px;
    background-color:#eae1e1;
    margin-left:2px;
    outline:none;
    color:#000000;
    font-size:12px;
    margin-top:4px;
    line-height:20px;
    padding:0px 6px 0px 6px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #8e8383;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}


.gg-total-input.active{
    background-color:#eae1e1;
}


.footer-total i{
    margin:8px 0px 0px 5px;
    cursor:pointer;
}


.go-to-input{

}


.footer-go-to input{
    height:20px;
    border:none;
    width:50px;
    background-color:#8e8383;
    margin-left:2px;
    outline:none;
    color:#000000;
    font-size:12px;
    margin-top:4px;
    line-height:20px;
    padding:4px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #eae1e1;

}



.gizmo-grid .gg-body{
    width:100%;
    background-color:#FFFFFF;
    position:relative;
    overflow:hidden;
    transition: width 0.2s ease;
}


.gizmo-grid .gg-body .gg-left-col{
    position:absolute;
    top:0px;
    left:0px;
    min-height:30px;
    width:100px;

}


.gizmo-grid .gg-body .gg-right-col{
    position:absolute;
    top:0px;
    left:100px;
    min-height:30px;
    width:300px;

}



/*///////////// START V SCROLL /////////////////////*/


.gizmo-grid .gg-vScroll{
    height: 100%;
    width:14px;
    background-color:#938686;
    position:absolute;
    right:0px;
    top:0px;
    border-left:1px solid #938686;
}


.gizmo-grid .gg-vScroll .gg-vScroll-container{
    height: 100%;
    width:100%;
    position:relative;
    background-color:#f8f8f8;
}

.gizmo-grid .gg-vScroll .gg-vScroll-container .vScroll-track{
    background-color:#f8f8f8;
    height:100%;
    width:14px;
    position:absolute;
    left:0px;
    top:2px;
}

.gg-vScroll-container .map{
    width:20px;
    height:3px;
    background-color:#ffdd00;
    border-top:1px solid #ccaa00;
    border-bottom:1px solid #ccaa00;
    position:absolute;

    top:10px;
    left:-5px;
}


.gizmo-grid .gg-vScroll .gg-vScroll-container .v-scroll-btns{
    height:28px;
    width:14px;
    right:0px;
    bottom:0px;
    position:absolute;
}






.gizmo-grid .gg-vScroll .gg-vScroll-container  .vScroll-dragger{
    height:28px;
    width:10px;
    background-color:#938686;
    left:0px;
    top:1px;
    margin-left:2px;
    position:absolute;
    cursor:pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    z-index:639;
    opacity: 0.5;

}

.gizmo-grid .gg-vScroll .gg-vScroll-container  .vScroll-dragger:hover{
    background-color:#7b7070;
    opacity: 0.8;
}

.gizmo-grid .gg-vScroll .gg-vScroll-container .gg-btn{
    width:14px;
    height:14px;
    border-top:1px solid #938686;
    text-align:center;
    position:relative;
    color:#938686;
    cursor:pointer ;
}

.gizmo-grid .gg-vScroll .gg-vScroll-container .gg-btn:hover{
    background-color:#c2c2c2;
    color:#5d5c5c;
}

.gg-vScroll-container .v-scroll-btns .gg-btn i{
    font-size:12px;
    position:absolute;
    left:4px;
    top:0px;
}

.v-scroll-btns .gg-btn-mouse-down,
.h-scroll-btns .gg-btn-mouse-down{
    color:#FFFFFF !important;
    background-color:#5d5c5c !important;
}



/*///////////// START H SCROLL /////////////////////*/
.gizmo-grid .gg-hScroll{
    width:100%;
    height:15px;
    background-color:#938686;
}

.gizmo-grid .gg-hScroll .gg-hScroll-container{
    height: 100%;
    width:100%;
    background-color:#6e6d6d;
    position:relative;
    border-top:1px solid #938686;
}

.gizmo-grid .gg-hScroll .gg-hScroll-container .hScroll-track{
    background-color:#f8f8f8;
    height: 100%;
    width:300px;
    position:absolute;
    left:300px;
    top:0;
}

.gizmo-grid .gg-hScroll .gg-hScroll-container .h-scroll-btns{
    width:28px;
    height:13px;
    background-color: #938686;
    right:14px;
    top:0px;
    position:absolute;
}

.gizmo-grid .gg-hScroll .gg-hScroll-container .h-scroll-btns .gg-btn{
    width:14px;
    height:14px;
    background-color: #f8f8f8;
    float:left;
    border-left:1px solid #938686;
    text-align:center;
    position:relative;
}


.gizmo-grid .gg-hScroll .gg-hScroll-container  .hScroll-dragger{
    height:10px;
    width:28px;
    background-color:#938686;
    left:0px;
    top:2px;
    margin-left:2px;
    position:absolute;
    cursor:pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

}

.gizmo-grid .gg-hScroll .gg-hScroll-container  .hScroll-dragger:hover{
    background-color:#7b7070;
}


.gizmo-grid .gg-hScroll .gg-hScroll-container .gg-btn:hover{
    background-color:#c2c2c2;
    color:#5d5c5c;
}

.gg-hScroll-container .h-scroll-btns .gg-btn i{
    color:#938686;
    font-size:12px;
    position:absolute;
    left:3px;
    top:1px;
}


.h-scroll-btns .gg-btn-mouse-down{
    color:#FFFFFF !important;
    background-color:#5d5c5c !important;
}

.h-scroll-btns .gg-btn-mouse-down i{
    color:#FFFFFF !important;
}


/*///////////////////////////////////////////////////////////////////////////////////*/
/*////////////////////////////////////// GIZMO MODAL ///////////////////////////////*/
/*///////////////////////////////////////////////////////////////////////////////////*/

.gg-modal{
    position:relative;
}

.gg-modal .gg-modal-top{
    height:35px;
    border-bottom:1px solid #938686;
}

.gg-modal .gg-modal-bottom{
    height:35px;
    border-top:1px solid #938686;
    text-align:right;
}

.gg-modal .gg-modal-bottom{
    height:35px;
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    -webkit-box-shadow: 0 -1px 1px 0 #dddddd;
    box-shadow: 0 -1px 1px 0 #dddddd;
    border-bottom:1px solid #938686;
}

.gg-modal .gg-modal-bottom > div{
    margin-right:10px;
}

.gg-modal .gg-modal-bottom .btn{
    -webkit-border-radius: 2px 2px 2px 2px !important;
    border-radius: 2px 2px 2px 2px !important;
    margin-left:2px;
    padding:2px 8px 2px 8px;
    margin-top:4px;
}

.gg-modal .gg-modal-bottom .btn-success{
    background-color:#33cc33;
}

.gg-modal .gg-modal-bottom .btn-success:hover{
    background-color:#3ff93f;
}

.gg-modal .gg-modal-bottom .btn-warning{
    background-color:#c73f4c;
}

.gg-modal-title{
    line-height:35px;
    padding-left:10px;
    font-size:14px;
    color:#363738;
    font-weight:bold;
}

.gg-modal .gg-modal-body{
    border-top:1px solid #dddddd;
    width:100%;
}

.gg-modal .gg-modal-body > div{
    margin:10px;
}

/* asyed037 */
.gizmo-grid .icon-center .grid-form-btn {
    left: calc(50% - 5px);
}


/* styles for grid on modal */
.modal-body-grid-ht {
    height: calc(100vh - 100px);
    padding: 7px;
   }

.modal-body-grid-ht .gg-loader {
    width: calc(100% - 14px) !important;
    height: calc(100% - 65px) !important;
}
.issueColumns{
    background-color : #ed8000;
}
